<template>
  <div>
    <div v-if="readonly">{{ formatter(innerValue) }}</div>
    <ValidationProvider v-else v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
      <ControlField
        grouped
        :horizontal="horizontal"
        :class="{ 'field-vertical': !horizontal }"
        v-bind="$attrs"
        :type="{ 'is-danger': errors[0], 'is-success': verified }"
        class="field"
        :custom-class="verified ? 'is-verified' : ''"
      >
        <o-datepicker
          ref="datepicker"
          expanded
          editable
          v-bind="$attrs"
          :append-to-body="false"
          :placeholder="datePlaceHolder"
          :disabled="disabled"
          :readonly="disabled"
          :horizontal="horizontal"
          :value="innerValue"
          :focused-date="focusedDate"
          :years-range="[-200, 200]"
          :date-formatter="formatter"
          :date-parser="parser"
          @input="onInput"
          @focus="positionDropdown"
        >
          <template slot="footer">
            <o-button
              :label="$t('calendar.today')"
              type="is-primary"
              @click="$emit('input', new Date())"
            />
          </template>
        </o-datepicker>
        <a v-if="hasDelete" class="clear-button" @click.prevent.stop="clearValue">
          <img v-svg-inline class="inline-icon" :src="require('@/assets/icons/fa/far/times.svg')" />
        </a>
      </ControlField>
      <p class="help is-danger">{{ errors[0] }}</p>
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: null
  }),
  computed: {
    focusedDate() {
      const now = new Date();
      const date: Date = this.innerValue;
      if (!date) return now;
      if (isNaN(date.getTime())) return now;
      return date;
    },
    datePlaceHolder() {
      return '2000-12-31';
    }
  },
  watch: {
    // Handles external model changes.
    value(newVal) {
      this.innerValue = typeof newVal === 'string' ? new Date(newVal) : newVal;
      if (isNaN(this.innerValue.getTime())) {
        this.innerValue = undefined;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = typeof this.value === 'string' ? new Date(this.value) : this.value;
      if (isNaN(this.innerValue.getTime())) {
        this.innerValue = undefined;
      }
    }
  },
  methods: {
    positionDropdown() {
      const dropdownEl = this.$el.querySelector('.dropdown');
      const dropdownContentEl = this.$el.querySelector('.dropdown-content');
      if (!dropdownEl || !dropdownContentEl) {
        return;
      }

      dropdownEl.style.flexDirection = 'column';
      dropdownContentEl.style.position = 'initial';
    },
    clearValue() {
      this.innerValue = null;
      this.$emit('input', null);
    },
    onInput(val: Date) {
      this.$emit('input', this.formatter(val));
    },
    formatter(val: Date): string {
      return dayjs(val, {
        format: 'YYYY-MM-DD'
      }).format('YYYY-MM-DD');
    },
    parser(val: string): Date {
      val = val.replaceAll('/', '-');
      const parts = val.split('-');
      if (parts.length === 3 && parts[0].length < 3 && parts[1].length < 3 && parts[2].length > 3) {
        parts.reverse();
        val = parts.join('-');
      }

      return dayjs(String(val), {
        format: 'YYYY-MM-DD'
      }).toDate();
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}

::v-deep .dropdown-content {
  position: fixed;
  /*left: 0;*/
  /*top: 0;*/
}

.field-vertical {
  position: relative;
  display: block;
}
.clear-button {
  position: absolute;
  cursor: pointer;
  color: #000;
  top: 36px;
  right: 10px;
  z-index: 99999;
}
</style>
